body {
  margin: 0;
  font-family: Poppins;
  color: black;
  background-color: #eee;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: 23pt;

  padding-bottom: 20px;
}

.App {
  text-align: center;
}

#bibtex_popup {
  font-family: 'Courier New', Courier, monospace;
  font-size: 10pt;
  color: black;
  width: fit-content;
  background-color: #ccc;
  border-radius: 10px;
  border: none;
  padding: 10px;
}

#authors {
  font-size: 13pt;
}

#authors a {
  color: #141;
  display: inline-block;
}

#authors sup {
  font-size: 13pt;
  margin-right: 9px;
}

#content_column {
  width: 950px;
  margin-left: auto;
  margin-right: auto;
}

.link_button {
  display: inline-block;
  font-size: 16pt;
  height: 1.5em;
  padding: 5px 15px 5px 15px;
  color: white;
  background-color: #ccc;
  border-radius: 5px;
  text-decoration: none;
  margin-right: 5px;
}